.calculator-container {
    padding: 20px;
    width: 50%;
    color: gray;
}

.emi-heading {
    margin-top: 0;
    color: #fff;
    background-color: black;
    padding: 5px 0px;
    font-size: 1.5rem;
    margin-bottom: 16px;
}

.calculator-container form .form-group {
    margin-bottom: 20px;
}

.calculator-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.input-number,
.input-text {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.cal-button {
    width: 100%;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cal-button:hover {
    background-color: red;
}

.input-text[readOnly] {
    background-color: #f0f0f0;
    border-color: #ccc;
    cursor: not-allowed;
}

.input-text[readOnly]:focus {
    border-color: #ccc;
    box-shadow: none;
}