/* container1 */
.heading{
    height: 230px;
}

.heading > h1{
    font-size: 4rem;
}
/* container1 end  */


/* container2  start */
.ulTextSize{
    font-size: 14px;
}

/* Container 3 */
.tabbable .nav-tabs {
   overflow-x: auto;
   overflow-y:hidden;
   flex-wrap: nowrap;
}
.tabbable .nav-tabs .nav-link {
  white-space: nowrap;
}

.tabbable button {
    color: #000;
    font-weight: 600;
}

.container3{
    background-color: #e5e5e5;
}

