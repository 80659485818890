*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

.logo{
  width: 10rem;
}



/* .icon{
        width: 80px;
        height: 80px;
}

#fourth{
  background-color: #eee;
  height: 500px;
}

#fifth{
  height: 600px;
} */