 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} 

.button {
    width: 300px;
    height: 75px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    text-align: center;
}

/* .button::before {
    content: "Click Me";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: green;
    left: 0;
    bottom: 100%;
    transition: bottom .5s;

} */

/* .button:hover::before {
    bottom: 0%;
} */

.image{
    width: 90%;
}